import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { appendImage, createMarkup } from "../utils";
import axios from "axios";
import { apiRoutes } from "../constants/apiRoutes";
import Helmet from "../components/Helmet";
import routes from "../components/constants/routes";

const PrivacyPolicy = () => {
  const [apiData, setApiData] = useState();
  const fetchApi = async () => {
    try {
      const constactres = await axios.get(apiRoutes.privacy);
      setApiData(constactres.data.data.attributes);
    } catch (error) {}
  };
  useEffect(() => {
    fetchApi();
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [apiData]);

  const seoData = {
    title: "Privacy Policy | Festival Events",
    description: "Privacy Policy",
    url: `${process.env.GATSBY_SITE_URL}${routes.PrivacyPolicy}/`,
  };

  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />

      <div style={{ minHeight: "40vh", padding: "10em 0" }}>
        <div className="container">
          <div className="row">
            <div
              className="rich-editor"
              dangerouslySetInnerHTML={createMarkup(apiData?.content)}
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
